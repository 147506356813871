.main_box {
    margin: auto;
    width: 100%;
    position: relative;
}
.main_content {
    position: relative;
    width: 100%;
    /* min-height: 616px; */
    height: 542px;
}

.main_content .slider_btn {
    width: 22px;
    height: 22px;
    top: 50%;
    z-index: 99;
    transform: translateY(-50%);
    position: absolute;
    background-color: rgba(111, 191, 225, 0.15);
    opacity: 0.8;
}
.main_content .slider_btn:hover {
    opacity: 1;
}
.main_content .left_side {
    left: -21px;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.main_content .right_side {
    right: -21px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.main_sidebar_box .top_input_box {
    width: 100%;
}
.main_sidebar_box .top_input_box > div input {
    padding: 0px 0px 0px 0px;
    font-family: Poppins;
    font-size: 0.729vw;
    font-weight: 400;
    width: 90%;
    text-align: start;
    line-height: 18px;
    color: rgba(111, 191, 225, 0.75);
}

.main_sidebar_box .top_input_box > div input::placeholder {
    font-size: 0.729vw;
}

.main_sidebar_box .top_input_box > div input:hover {
    color: rgba(111, 191, 225, 1);
}
.main_slider {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 20px; */
    position: relative;
}
.main_slider p {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.main_slider_box {
    width: 12px;
    margin: 10px auto;
    display: flex;
    height: 368px;
    position: relative;
}

.slider {
    width: 6px;
    position: absolute;
    left: 50%;
    height: 368px;
    transform: translateX(-50%);
    /* to remove the white box showing on the slider */
    background: none !important;
    border-radius: 10px;
    background-color: transparent !important;
}

.slider::before {
    background-color: transparent !important;
}

.track {
    width: 6px;
    border-radius: 10px;
    height: 100%;
    background-color: #007bff;
    background: #3494e6;
    background: linear-gradient(360deg, #4a95d6 -1.44%, #bc54ff 52.83%, #f14f98 99.49%);
}

.drag_dot .thumb {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 4px 4px 0px #00000040;
    z-index: 1;
}

.dot_info {
    width: 25px;
    border-radius: 50%;
    height: 25px;
    background-color: #fff;
    color: #000;
    position: absolute;
    display: flex;
    z-index: 1;
    font-size: 12px;
    line-height: 0;
    font-weight: 600;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    justify-content: center;
    font-size: 11px;
    bottom: -17px;
    z-index: 99;
}

.dot_info_hover {
    display: none;
}
.drag_dot:hover .dot_info_hover {
    display: flex;
    z-index: 999;
}
.drag_dot {
    cursor: pointer;
}

.main_slider .title_content {
    position: absolute;
    left: 6px;
    color: #fff;
    margin: 0;
    top: -7px;
    gap: 2px;
    display: flex;
    z-index: -1;
    max-height: 50px;
    overflow-y: auto;
    flex-direction: column;
}

/* width */
.main_slider .title_content::-webkit-scrollbar {
    border-radius: 10px;
    margin: 5px;
    border-radius: 10px;
    width: 8px;
    background: rgba(255, 255, 255, 0.2);
}

.title_content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.07);
    width: 4px;
}

.main_slider .title_content .titles {
    display: flex;
    gap: 5px;
    align-items: center;
}

.title_content .not-match span {
    border: 1px transparent !important;
    border-radius: 2px !important;
    background-color: rgba(111, 191, 225, 0.5) !important;
}

.main_slider .title_content .titles span {
    font-size: 0.625vw;
    max-width: 6.615vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid transparent;
    padding: 0 15px;
    height: 20px;
    color: rgba(255, 255, 255, 1);
    opacity: 0.8;
    background: transparent;
    margin: 2px 0;
    border-radius: 0;
}
.main_slider .title_content .titles span label {
    line-height: 20px;
}
.main_slider .title_content .titles .remove_title {
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    background: transparent;
}
.main_slider .title_content .titles .remove_title svg path {
    fill: rgba(111, 191, 225, 0.75);
}

.add_info {
    position: absolute;
    left: 50%;
    background-color: rgba(111, 191, 225, 0.75);
    width: 24px !important;
    transform: translateX(-58%);
    height: 24px !important;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    z-index: 99;
    justify-content: center;
    cursor: pointer;
}

.selected-title {
    border: 1px solid !important;
    border-image-source: linear-gradient(90deg, #4a95d6 0%, #bc54ff 51%, #f14f98 100%) !important;
    border-image-slice: 1 !important;
    border-radius: 10px;
    max-width: 150px;
    height: 20px;
    background: rgba(111, 191, 225, 0.15) !important;
    opacity: 1 !important;
}

.detail-input input {
    color: linear-gradient(90deg, #4a95d6 0.12%, #bc54ff 34.64%, #f14f98 64.31%);
}
.input_box {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
}
.input_box > div {
    position: relative;
    max-width: 392px;
    width: 100%;
}

.input_box > div .error_msg {
    position: absolute;
}

.input_box .add_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    min-width: 24px !important;
    height: 24px !important;
    border: none;
    background-color: transparent;
}
.input_box .add_btn svg path {
    fill: rgba(111, 191, 225, 0.75);
}
.input_box .add_btn:focus {
    border: 0;
    outline: 0;
}

#leftside {
    height: 368px;
    margin-top: 67px;
    width: 45%;
    left: 0.417vw;
    position: absolute;
}
#leftside::before {
    position: absolute;
    content: none;
    width: 1px;
    height: 100%;
    left: 13%;
    background-color: #4645455c;
}
#leftside:hover::before {
    content: '';
}

#leftside .vertical-slider {
    margin: 0 !important;
    width: 100%;
    height: 368px;
    border-radius: 10px;
}

#leftside .vertical-slider::before {
    position: absolute;
    content: '';
    left: 23px;
    width: 1px;
    height: 100%;
    display: none;
    background-color: rgba(255, 255, 255, 0.1);
}

#leftside .scrollthumb ~ .vertical-slider::before {
    display: block;
}

#leftside .main_thumb {
    width: 100%;
}

#leftside .horizontal-line {
    width: calc(100% - 51px);
    overflow: hidden;
    position: absolute;
    top: 50%;
    margin: 0 !important;
    transform: translateY(-50%);
    display: none;
    right: 0;
}

#leftside .horizontal-line svg {
    width: 100%;
}

.scrollthumb:hover ~ .horizontal-line {
    display: block !important;
}

#leftside .scrollthumb {
    left: 0 !important ;
    width: fit-content;
    cursor: pointer;
    position: relative;
}

#leftside .thumb-container,
#leftside .scrollthumb:focus-visible {
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
}

#leftside .scroll_flag {
    width: 51px;
    padding: 3px;
    height: 24px;
    border-radius: 15px;
    height: auto;
    align-items: center;
    justify-content: space-around;
    display: flex;
}
#leftside .scroll_flag svg path {
    fill: rgba(111, 191, 225, 0.75);
}
#leftside .main_thumb .scroll_count {
    position: absolute;
    right: 10px;
    width: 24px;
    height: 18px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 9px;
    display: none;
}

#leftside .scrollthumb:hover ~ .scroll_count {
    display: flex;
    align-items: center;
    justify-content: center;
}

#leftside .scrollthumb:hover .scroll_flag,
#leftside .scroll_flag.scroll_flag:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

#leftside .scroll_flag .arrow {
    width: 16px;
    height: 16px;
    border: 1px solid rgba(111, 191, 225, 0.75);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#leftside .scroll_flag:hover .arrow {
    border-color: rgba(111, 191, 225, 0.75);
}

#leftside .scroll_flag:hover .scroll_flag svg path {
    fill: rgba(111, 191, 225, 0.75);
}

#leftside .scroll_flag .roted_arrow svg {
    transform: rotate(180deg);
    transition: 0.5s all;
}
#leftside .scroll_flag .flage_svg {
    opacity: 0.5;
}
#leftside .scrollthumb:hover .scroll_flag .flage_svg {
    opacity: 1;
}
#leftside .scroll_flag .arrow svg {
    color: rgba(255, 255, 255, 1);
    transition: 0.5s all;
    font-size: 9.5px;
}

#leftside .scrollthumb .info svg {
    color: #fff;
    font-size: 20px;
}

#leftside .scrollthumb .scroll_info {
    position: absolute;
    top: -35px;
    text-align: center;
    width: 190px;
    padding: 8px 5px;
    display: none;
    border-radius: 8px;
    background-color: rgba(0, 20, 255, 1);
}

#leftside .scrollthumb:hover .scroll_info {
    display: block;
}

#leftside .scrollthumb .scroll_info p {
    font-size: 10px;
    color: #fff !important;
}

.eva_sidebar .MuiDrawer-paper {
    height: calc(100% - 202px) !important;
}

.eva_sidebar .MuiDrawer-paper::-webkit-scrollbar {
    border-radius: 10px;
    margin: 5px;
    border-radius: 10px;
    width: 7px;
    display: none;
    background: rgba(255, 255, 255, 0.2);
}

.eva_sidebar .MuiDrawer-paper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.07);
}

.new_add_btn {
    left: 80px;
    bottom: 25px;
}

.bg-dark-blue-radial {
    overflow-x: hidden;
    background: rgba(17, 17, 63, 1) !important;
    height: -webkit-fill-available;
    overflow-y: hidden;
    height: calc(100%);
}

.loading_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    border-radius: 5px;
    min-width: 24px !important;
    height: 24px !important;
    background: rgba(255, 255, 255, 0.1) !important;
}
.loading_btn .animate-spin {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid;
    border-color: #dbdcef;
    border-right-color: rgba(255, 255, 255, 0.1);
}

.error_msg {
    font-size: 9px;
    color: red;
    text-transform: capitalize;
}

.bottom_submit_btn .add_btn {
    width: 1.667vw;
    height: 1.667vw;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border: 1px solid rgba(111, 191, 225, 0.25);
    justify-content: center;
    background-color: rgba(111, 191, 225, 0.1);
}
.bottom_submit_btn .add_btn:hover {
    border-color: rgba(111, 191, 225, 0.5);
}

.bottom_submit_btn .add_btn svg path {
    fill: rgba(111, 191, 225, 0.75);
}
.bottom_submit_btn .add_btn:hover svg path {
    fill: rgba(111, 191, 225, 1);
}

.bottom_submit_btn .add_btn svg {
    scale: 1.33;
}
.disable_btn {
    opacity: 0.6;
    pointer-events: none;
}
.bottom_submit_btn .loading_btn {
    width: 1.667vw;
    height: 1.667vw !important;
    border: 1px solid rgba(111, 191, 225, 0.25);
    padding: 0;
    background-color: rgba(111, 191, 225, 0.1);
}

.metaMarker-title:focus.metaMarker-title:focus::placeholder {
    display: none;
}

.plus_btn {
    position: relative;
}

.plus_btn svg path {
    fill: rgba(15, 16, 68, 0.75);
}

.first_content {
    border-radius: 15px;
    max-width: 450px !important;
    width: 100%;
    margin: 0 auto 15px;
    min-height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #00000040;
}

.first_content p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    max-width: 353px;
    color: #ffffff;
    width: 100%;
    margin: auto;
}

@media (max-width: 1600px) {
    .first_content {
        padding: 5px;
    }
    .first_content p {
        font-size: 11px;
    }
}

.bottom_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.main_add_detail {
    position: relative;
    width: 100%;
    height: 80px;
    overflow: hidden;
    margin-top: 25px;
}

.main_add_detail .plus_btn {
    margin-top: 15px;
}
@media only screen and (min-width: 1600px) {
}
@media (max-width: 1500px) {
    .first_content {
        padding: 7px;
    }
}

.slider_one {
    height: calc(100% - 30px);
}
.slider_two {
    height: fit-content;
}
.bg-dark-blue-radial::-webkit-scrollbar {
    display: none;
}
.hide_bottom_box {
    height: 50px;
}

.custom_select {
    width: 7.969vw;
}
.custom_select .select_label {
    height: 24px;
    width: 100%;
    padding: 0 9px;
    border: 1px solid rgba(111, 191, 225, 0.25);
    background: rgba(111, 191, 225, 0.1);
    border-radius: 5px;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}
.policy_txt {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    margin-top: 10px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}
.custom_select .active.select_label {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.custom_select .select_label p {
    font-family: Poppins;
    font-size: 0.521vw;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 0.521vw;
    height: 16px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding-right: 5px;
    justify-content: center;
}

.custom_select .select_label p span {
    color: rgba(111, 191, 225, 0.75);
}
.custom_select .select_label p img {
    margin-left: -3px;
    margin-top: 4px;
}
.custom_select .select_label svg path {
    fill: rgba(111, 191, 225, 0.75);
}
.custom_select .option_list {
    width: 153px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0;
    max-height: 150px;
    overflow: auto;
    margin-top: 1px;
    position: absolute;
    z-index: 9;
    background: rgba(255, 255, 255, 1);
}
.custom_select .option_list.disable li {
    opacity: 0.6;
}

.custom_select .option_list::-webkit-scrollbar {
    display: none;
}
.custom_select .option_list li {
    list-style: none;
    padding: 0 5px;
    font-family: Poppins;
    height: 24px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 10px;
    gap: 5px;
    font-weight: 500;
    color: rgba(197, 96, 244, 1);
    display: flex;
    align-items: center;
    line-height: 15px;
}
.custom_select .option_list li img {
    margin-top: 5px;
    width: 18px;
}
.custom_select .option_list li:hover {
    background: rgba(247, 247, 248, 1);
}

.custom_select .option_list li span {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
}

.custom_select .option_list li.active {
    background: rgba(247, 247, 248, 1);
}

.main_sidebar_box .custom_check .check_box {
    width: 16px;
    border: 1px solid rgba(111, 191, 225, 0.75);
    height: 16px;
    border-radius: 4px;
}
.main_sidebar_box .custom_check span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(111, 191, 225, 0.75);
}
.check_tooltip_box {
    width: 226px;
    height: auto;
    top: 24px;
    padding: 8px;
    gap: 8px;
    z-index: 9;
    border-radius: 7px;
    background: rgba(0, 20, 255, 0.75);
    position: absolute;
    left: 50%;
    display: none;
    transform: translateX(-50%);
}
.custom_check:hover .check_tooltip_box {
    display: block;
}

.check_tooltip_box p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
}

.check_tooltip_box p b {
    font-weight: 700;
}
.main_sidebar_box .custom_check:hover .check_box {
    border-color: rgba(255, 255, 255, 0.5);
}

.main_sidebar_box .loading_overly {
    position: fixed;
    width: 100%;
    left: 0;
    height: 100vh;
    top: 0;
    z-index: 999;
}

/* new modify eva side bar ccs ============== */

.file_upload_box {
    /* width: 100%; */
    /* padding: 0 20px; */
    border-radius: 4px;
    /* min-height: 50px; */
    background: rgba(111, 191, 225, 0.1);
}
.sidebar_common_btn {
    width: 1.667vw;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.667vw;
    border: 1px solid rgba(111, 191, 225, 0.25);
}
.file_upload_box .upload_btn {
    position: relative;
    overflow: hidden;
}
.file_upload_box .upload_btn:hover {
    border-color: rgba(111, 191, 225, 0.5);
}
.upload_btn_box:hover .tooltip_box {
    display: flex;
    left: 0;
    top: 35px;
}
.upload_btn_box .file_info {
    width: 230px;
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    padding: 5px 8px;
    display: none;
    align-items: center;
    background-color: rgba(17, 17, 63, 0.75);
}
.upload_btn_box:hover .file_info {
    display: flex;
}
.upload_btn_box .file_info p {
    color: rgba(255, 255, 255, 0.75);
    font-family: Poppins;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
}
.upload_btn_box .file_info p span {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.upload_btn_box .file_info .remove_file svg path {
    fill: rgba(255, 255, 255, 0.5);
}
.eay_btn:hover .tooltip_box {
    display: flex;
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
}

.file_upload_box .upload_btn .after_icon {
    display: none;
}
.file_upload_box .upload_btn:hover .after_icon {
    display: block;
}
.file_upload_box .upload_btn:hover .before_icon {
    display: none;
}
.border-none {
    border: 0;
}
.file_upload_box .active_file {
    background-color: rgba(12, 30, 234, 1);
    border-color: rgba(12, 30, 234, 1);
}
.file_upload_box .active_file:hover {
    border-color: rgba(12, 30, 234, 1);
    background: rgba(12, 30, 234, 1);
}
.file_upload_box .active_file:hover svg {
    opacity: 0.9;
}

.file_upload_box .active_file::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    left: 17px;
    top: 6px;
    border-radius: 50%;
    z-index: 1;
    outline: 1px solid rgba(0, 20, 255, 1);
    background-color: rgba(255, 191, 120, 1);
}
.file_upload_box .active_file:hover::before {
    outline: 1px solid rgba(0, 20, 255, 1);
}

.file_upload_box [type='file'] {
    position: absolute;
    opacity: 0;
    max-width: 32px;
    width: 100%;
    z-index: 99;
    height: 32px;
    left: 0;
    top: 0;
    cursor: pointer;
}
.file_upload_box [type='file']::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

/* .file_upload_box .eay_btn {
    background-color: rgba(111, 191, 225, 0.75);
}
.file_upload_box .eay_btn:hover {
    background-color: rgba(111, 191, 225, 1);
} */

.file_upload_box .evaluate_mode {
    background-color: rgba(96, 244, 200, 0.85);
}
.file_upload_box .evaluate_mode:hover {
    background-color: rgba(96, 244, 200, 1) !important;
}
.sidebar_close_btn {
    border: 0;
    width: 28px;
}
.sidebar_close_btn svg path {
    fill: rgba(111, 191, 225, 0.75);
}
.sidebar_close_btn:hover svg path {
    fill: rgba(111, 191, 225, 1);
}
.right_border {
    display: block;
    height: 28px;
    width: 2px;
    background-color: rgba(111, 191, 225, 0.25);
}

.main_sidebar_box .main_container {
    padding: 10px 20px 0 20px;
    height: calc(100% - 110px);
    overflow-y: auto;
    background-color: rgb(26 34 80);
}

.main_sidebar_box .protocol_detail,
.main_sidebar_box .protocol_detail .top_date_box {
    background-color: rgb(26 34 80);
}
.top_input_box svg path {
    fill: rgba(111, 191, 225, 0.75);
}

.main_sidebar_box .select_type_box {
    width: max-content;
}

.main_sidebar_box .main_content {
    border-radius: 15px;
    padding: 15px;
    /* background-color: rgba(17, 17, 63, 1); */
    background: rgba(111, 191, 225, 0.1);
    border: 1px solid rgba(111, 191, 225, 0.25);
}
.action_box {
    margin-bottom: 4px;
}
.action_box p {
    font-family: Poppins;
    font-size: 0.625vw;
    font-weight: 500;
    line-height: 18px;
    color: rgba(111, 191, 225, 0.75);
    text-align: left;
}
.action_button_box {
    gap: 5px;
}

.action_button_box button {
    width: 24px;
    position: relative;
    height: 24px;
}

.action_button_box .close_icon_btn svg path {
    fill: rgba(255, 255, 255, 0.75);
}

.action_button_box .refresh_btn svg path {
    fill: rgba(111, 191, 225, 0.5);
}

.action_button_box .replace_btn:hover svg path,
.action_button_box .close_icon_btn:hover svg path,
.action_button_box .refresh_btn:hover svg path {
    fill: rgba(255, 255, 255, 1);
}
.action_button_box .save_btn .hover_save {
    display: none;
}
.action_button_box .save_btn:hover .hover_save {
    display: block;
}
.action_button_box .save_btn:hover .save_icon {
    display: none;
}
.action_button_box button:hover .tooltip_box {
    display: flex;
    top: 23px;
}
.action_button_box button:hover .tooltip_box p {
    font-size: 10px !important;
}

.refresh_btn_wrapper:hover .tooltip_box {
    display: flex;
}
.action_button_box .close_icon_btn svg {
    width: 8px;
    height: 8px;
}

.action_button_box .close_icon_btn::before {
    position: absolute;
    content: '';
    width: 1px;
    left: -3px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.1);
}
.arrow_disable {
    opacity: 0.5 !important;
}

.updated_screen .screen_one {
    display: none;
    visibility: hidden;
}

.updated_screen.show_new_screen .screen_one {
    display: flex;
    visibility: visible;
    justify-content: space-between;
}
.updated_screen::after,
.updated_screen::before {
    content: none;
}
.updated_screen.show_new_screen .slider_btn {
    display: none;
}
.loading_screen {
    display: none;
    visibility: hidden;
}
.show_loading .loading_screen {
    display: block;
    visibility: visible;
}
.updated_screen .main_slider .title_content .titles .remove_title,
.updated_screen .main_slider .add_info,
.updated_screen .main_add_detail {
    display: none;
    visibility: hidden;
}

.updated_screen .main_slider {
    width: 46%;
    align-items: start;
    margin-bottom: 0;
}
.updated_screen .main_slider .main_slider_box {
    margin-left: 20px;
}

.updated_screen .main_slider .title_content .titles span {
    border-radius: 3px;
    width: 101px;
}

.updated_screen .show_num {
    display: none;
}

.main_slider h6 {
    /* margin-top: 99px; */
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    word-break: break-all;
}
.updated_screen .main_slider p {
    margin-left: 20px;
}

.main_slider.right_side_nuances .nuances_remove_btn {
    visibility: visible !important;
    display: flex !important;
    border-radius: 5px !important;
    background-color: rgba(111, 191, 225, 0.1) !important;
}

.show_new_screen::before {
    position: absolute;
    content: '';
    left: 50%;
    width: 1px;
    top: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
}

.main_slider.right_side_nuances .nuances_remove_btn svg path {
    fill: rgba(111, 191, 225, 1) !important;
}

.right_side_nuances .track {
    /* outline: 1px solid rgba(255, 255, 255, 0.13); */
    background: linear-gradient(360deg, rgba(153, 244, 190, 0.1) -1.44%, rgba(155, 214, 240, 0.1) 99.49%);
}
.right_side_nuances .main_slider_box {
    margin-top: 4px;
}

.bottom_num {
    margin-left: 23px !important;
}

.loading_screen .skeleton1 {
    width: 7.813vw;
    height: 15px;
    margin-bottom: 15px;
}

.loading_screen .skeleton3 {
    width: 13.021vw;
}

.loading_screen .skeleton1 .react-loading-skeleton {
    padding: 3px;
}
.loading_screen .react-loading-skeleton {
    background: #6fbfe11a !important;
    border-radius: 10px;
    overflow: hidden;
}
.react-loading-skeleton::after {
    background: #6fbfe10a !important ;
}

.right_side_nuances .nuance_list {
    height: 425px;
    overflow-y: auto;
    margin-top: 15px;
}

.right_side_nuances .nuance_list::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.07);
}

.right_side_nuances .nuance_list::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
}
.right_side_nuances .nuance_list ul {
    padding-right: 10px;
}
.right_side_nuances .nuance_list ul li .nuances_count {
    width: 20px;
    border-radius: 50px;
    height: 20px;
    font-family: Poppins;
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    line-height: 15px;
    color: rgba(15, 16, 68, 1);
    background-color: rgba(255, 255, 255, 1);
}
.right_side_nuances .nuance_list ul li {
    gap: 6px;
    margin-bottom: 7px;
}
.right_side_nuances .nuance_list ul li .nuances_name {
    background-color: rgba(111, 191, 225, 0.5);
    width: 4.844vw;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: 2px;
}
.right_side_nuances .nuance_list ul li .nuances_name p {
    align-items: center;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
}
.right_side_nuances .nuance_list ul li button {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: rgba(111, 191, 225, 0.1);
}
.right_side_nuances .nuance_list ul li button svg path {
    fill: rgba(111, 191, 225, 1);
}

.updated_screen .main_slider .title_content .titles .selected-title {
    border: none !important;
}

.updated_screen .drag_dot .title_content {
    display: none;
}

.updated_screen .drag_dot:hover .title_content {
    display: block;
}

.updated_screen .drag_dot:hover .show_num {
    display: flex;
}

.evaluateMode {
    box-shadow: 0px 0px 15px 0px rgba(96, 244, 200, 0.15);
    border-color: rgba(96, 244, 200, 0.5) !important;
}

.evaluateMode .selected-title {
    border-color: rgba(96, 244, 200, 0.5) !important ;
    box-shadow: 0px 0px 15px 0px rgba(96, 244, 200, 0.15) !important;
    border-image: none !important;
    border-radius: 2px !important;
}

.file_loading_btn {
    background: rgba(111, 191, 225, 0.1) !important;
    border-color: rgba(111, 191, 225, 0.25) !important;
}
.file_loading_btn::before {
    content: none !important;
}
.file_loading {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid rgba(111, 191, 225, 0.2);
    border-right-color: rgba(111, 191, 225, 1);
    animation: l2 1s infinite linear;
}
@keyframes l2 {
    to {
        transform: rotate(1turn);
    }
}

.nuance_info:hover .tooltip_box {
    display: flex;
    left: 0;
    z-index: 99;
    top: 20px;
    max-width: 350px;
    width: max-content;
    padding: 7px 10px;
    height: auto;
    background-color: rgba(0, 20, 255, 1);
}

.nuance_info:hover .tooltip_box p {
    font-size: 10px !important;
    color: rgba(255, 255, 255, 1) !important;
    white-space: wrap;
    line-height: 15px;
    font-weight: 400;
}
.right_side_nuances .nodata {
    font-size: 12px;
    text-align: center;
}
.main_container .bottom_arrow_box {
    height: 50px;
    margin: auto;
    justify-content: center;
    width: 100%;
    align-items: center;
}
.length_counter {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(111, 191, 225, 0.5);
}

.main_sidebar_box .protocol_detail .protocol_heading .left_input_box {
    width: 78%;
}

.top_input_box .protocol_name {
    width: 10vw;
}
.main_sidebar_box .protocol_detail .protocol_heading .protocol_name h5 {
    max-width: 100%;
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.main_sidebar_box .protocol_detail .protocol_heading .protocol_name svg {
    display: none;
    scale: 1.3;
}
.main_sidebar_box .protocol_detail .protocol_heading .protocol_name:hover svg {
    display: block;
    scale: 1.3;
}

.main_sidebar_box .protocol_detail {
    height: unset;
}

.blue_print_modal_loading .main_loader {
    height: 60vh;
}
