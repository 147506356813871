/* log css  */
.log_filter_select {
    position: relative;
}

.log_filter_select .m_select {
    width: auto;
}
.log_filter_select .log-select button {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0 27px;
    background-color: rgb(64 65 96 / 58%);
    max-width: 100% !important;
}

.log_filter_select .log-select button:hover {
    border-color: rgba(255, 255, 255, 0.5);
}
.log_filter_select .log-select button span {
    line-height: unset;
    color: rgba(255, 255, 255, 0.75);
}
.log_filter_select .log-select_disable button{
    padding-right: 53px;
}
.log_filter_select .log-select button svg path {
    fill: rgba(255, 255, 255, 0.75);
}
.log_filter_select ul {
    background-color: rgba(15, 16, 68, 1);
    padding: 5px;
}
.log_filter_select ul li {
    height: 32px;
    display: flex;
    align-items: center;
}
.log_filter_select ul li svg {
    display: none;
}
.log_filter_select ul li span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(255, 255, 255, 0.75);
}
.log_filter_select svg path {
    fill: #fff;
}

.log_filter_select ul li .font-bold {
    font-weight: normal !important;
}

.log_filter_select ul li:hover {
    background-color: rgba(255, 255, 255, 0.25);
}
.log_filter_select ul li[aria-selected='true'] {
    background-color: rgba(255, 255, 255, 0.25);
}
.analytics-log-list {
    padding-right: 10px;
}
.analytics-log-list li p {
    padding-left: 0;
}
.analytics-log-list li p.protocol_nuances,
.analytics-log-list li p.protocol_name {
    padding-right: 14px;
}
.analytics-log-list li p span {
    white-space: nowrap;
    display: block;
    gap: 10px;
}
.analytics-log-list li p:hover .scroll_span {
    animation: scroll_text 5s linear infinite;
}

@keyframes scroll_text {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(5%);
    }
}

.analytics-log-list li p span:hover {
    text-overflow: unset;
}
.analytics-log-list li p label {
    display: block;
    width: 100%;
    padding: 0 10px 0 0;
    margin-left: 3px;
}
.analytics-log-list li p .date_time {
    width: 153px;
}
.analytics-log-list li p .protocolType {
    width: 35px;
    display: flex;
    gap: 10px;
}
.analytics-log-list li p .protocolType img {
    margin-top: 4px;
}
.analytics-log-list li p .name {
    width: 226px;
}

.analytics-log-list li p .nuances {
    width: 210px;
}

.analytics-log-list li p .score {
    width: 51px;
}

.graph_box .search-input {
    border-radius: 7px !important;
    height: 32px;
    max-width: 427px;
}

.log_filter_select .starIcon {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: auto;
    justify-content: flex-end;
}
.log-select_disable {
    opacity: 0.7;
}

.log_filter_select .starIcon .main_star_button {
    margin: 0 16px;
}

.log_filter_select .m_select > div {
    left: 10px !important;
}

.log_header {
    margin-top: 25px;
}

.log_header ul li {
    font-family: Poppins;
    font-size: 12px;
    gap: 3px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: rgba(255, 255, 255, 0.75);
}

.analytics-log-list {
    margin-top: 15px;
}

.log_header ul li:nth-child(1) {
    max-width: 170px;
    width: 100%;
    padding-left: 7px;
}
.log_header ul li:nth-child(2) {
    max-width: 52px;
    width: 100%;
}
.log_header ul li:nth-child(3) {
    max-width: 265px;
    width: 100%;
}
.log_header ul li:nth-child(4) {
    max-width: 240px;
    width: 100%;
}

.log_header ul li.icon1 svg path:nth-child(2) {
    fill: transparent !important;
}
.log_header ul li.icon2 svg path:nth-child(1) {
    fill: transparent !important;
}

.analytics-log-list .no_data{
    color: #fff;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    text-transform: capitalize;
}