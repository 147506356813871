#ctrl {
    --panel-bg: #fff;
    --heading-bg: rgba(15, 16, 68, 0.03);
    --box-bg: rgba(15, 16, 68, 0.03);
    --heading-text-color: rgba(15, 16, 68, 0.75);
    --text-color: rgba(15, 16, 68, 0.5);
    --border-color: rgba(15, 16, 68, 0.1);
    --scroll-bar-color: rgba(15, 16, 68, 0.03);
    --scroll-thumb-color: rgba(15, 16, 68, 0.031);
    --label-bg-color: rgba(15, 16, 68, 0.05);
    --skelton-bg: rgba(15, 16, 68, 0.03);
    --box-shadow: none;
    --width: auto;
    --left: 0;
    --position: unset;
    --radius: 0;
}

#edit-mode {
    --panel-bg: rgba(111, 191, 225, 0.1);
    --heading-bg: rgba(111, 191, 225, 0.1);
    --box-bg: rgba(17, 17, 63, 1);
    --heading-text-color: rgba(111, 191, 225, 0.75);
    --text-color: rgba(111, 191, 225, 0.5);
    --border-color: rgba(255, 255, 255, 0.1);
    --scroll-bar-color: rgba(111, 191, 225, 0.1);
    --scroll-thumb-color: rgba(111, 191, 225, 0.1);
    --label-bg-color: rgba(111, 191, 225, 0.05);
    --skelton-bg: rgba(111, 191, 225, 0.033);
    --left: 0.5%;
    --box-shadow: 0px 4px 10px 0px rgba(15, 16, 68, 0.12);
    --width: auto;
    --position: unset;
    --absolute: absolute;
    --radius: 0;
}

.protocol_detail .skeleton_bg {
    background-color: var(--skelton-bg) !important;
    max-width: 450px;
    width: 100%;
    height: 30px;
}
.protocol_detail hr {
    border-color: var(--border-color);
}
.protocol_detail {
    top: 1%;
    overflow: hidden;
    left: var(--left);
    box-shadow: var(--box-shadow);
    width: var(--width);
    background: var(--panel-bg);
    z-index: 9;
    height: 98%;
    position: var(--position);
    border-radius: var(--radius);
}

.protocol_detail .protocol_heading {
    height: 2.917vw;
    width: 100%;
    padding: 0 20px;
    background: var(--heading-bg);
}
.protocol_detail .protocol_heading .round {
    min-width: 1.25vw;
    height: 1.25vw;
}
.protocol_detail .protocol_heading .round .circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(111, 90, 232, 1);
}

.protocol_detail .protocol_heading h5 {
    font-family: Poppins;
    font-size: 0.729vw;
    max-width: 13.021vw;
    width: 100%;
    font-weight: 400;
    color: var(--heading-text-color);
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.protocol_detail .protocol_heading svg path {
    fill: var(--text-color);
    fill-opacity: 1;
}
.protocol_detail .protocol_heading svg:hover path {
    fill: var(--heading-text-color);
}
.protocol_detail .top_date_box {
    height: 2.604vw;
    text-align: end;
    padding: 0 20px;
    border-bottom: 1px solid var(--border-color);
}
.protocol_detail .top_date_box .rsp-icon {
    width: 1.667vw;
    height: 1.667vw;
}
.protocol_detail .top_date_box .sidebar_common_btn {
    border: 0;
}
.protocol_detail .delete_btn {
    width: 1.667vw;
    height: 1.667vw;
}
.protocol_detail .delete_btn svg path {
    fill: rgba(111, 191, 225, 0.5);
}
.bottom_arrow_box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom_arrow_box .delete_btn {
    width: 1.667vw;
    height: 1.667vw;
    border-radius: 4px;
}
.bottom_arrow_box .delete_btn:hover,
.protocol_detail .top_date_box .sidebar_common_btn:hover {
    background-color: #6fbfe11a;
}
.bottom_arrow_box .delete_btn:hover svg path {
    fill: var(--heading-text-color);
}
.protocol_detail .top_date_box span {
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: var(--text-color);
}
.protocol_detail .content {
    padding: 15px 15px 0 15px;
    height: calc(98% - 105px);
    overflow-y: auto;
    margin: 5px;
    width: -webkit-fill-available;
    overflow-x: hidden;
    position: var(--absolute);
}

.protocol_detail .content .protocol_text {
    padding: 4px 7px;
    border-radius: 5px;
    min-height: 24px;
    width: fit-content;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    background: var(--box-bg);
    color: var(--heading-text-color);
}
.protocol_detail .content .description {
    background: var(--box-bg);
    border-radius: 5px;
    width: 100%;
    padding: 15px;
}
.protocol_detail .content .description.sub_information {
    padding-bottom: 0;
}
.protocol_detail .content .sub_information .sub_content ol li,
.protocol_detail .content .sub_information .sub_content ul li,
.protocol_detail .content .description p {
    color: var(--text-color);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
.top_description .mini_content p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.top_description .mini_content {
    max-height: 54px;
    overflow-y: auto;
}
.top_description .mini_content:hover p {
    overflow: visible;
    -webkit-box-orient: unset;
    -webkit-line-clamp: unset;
    line-clamp: unset;
}
.protocol_detail .content .sub_information .sub_content ol {
    list-style-type: decimal;
    margin-left: 10px;
    padding-left: 10px;
}
.protocol_detail .content .sub_information h2 {
    color: var(--heading-text-color);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.protocol_detail .content .sub_information .sub_content {
    max-height: 120px;
    overflow-y: auto;
}
.top_description .mini_content::-webkit-scrollbar,
.main_sidebar_box .main_container::-webkit-scrollbar,
.protocol_detail .content::-webkit-scrollbar,
.protocol_detail .content .sub_information .sub_content::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    background: var(--scroll-bar-color);
}
.top_description .mini_content::-webkit-scrollbar-thumb,
.main_sidebar_box .main_container::-webkit-scrollbar-thumb,
.protocol_detail .content::-webkit-scrollbar-thumb,
.protocol_detail .content .sub_information .sub_content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    background: var(--scroll-thumb-color);
}

.protocol_detail .content .sub_information .sub_content ul li::before {
    position: absolute;
    content: '';
    border-radius: 50%;
    left: -10px;
    top: 7px;

    width: 4px;
    height: 4px;
    background-color: var(--text-color);
}

.nuanced {
    height: 40px;
    width: 90%;
    padding: 0 10px;
}

.nuanced .low {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    opacity: 0.6;
    color: var(--heading-text-color);
}
.nuanced .nuance_bar {
    background: linear-gradient(310deg, #27444900 -1.43%, #6fbfe1de 103.6%),
        linear-gradient(42deg, rgba(235, 103, 213, 0.5) 0.4%, rgb(192 81 221) 100.13%),
        linear-gradient(
            325deg,
            rgb(53 67 80 / 64%) -1.44%,
            rgb(153 69 207 / 50%) 52.83%,
            rgba(241, 79, 152, 0.5) 99.49%
        );
    /* max-width: 265px; */
    width: 100%;
    border-radius: 50px;
    height: 6px;
    padding: 0 2px;
    /* margin: auto; */
    opacity: 0.9;
    position: relative;
}
.nuance_bar .trigger_overly {
    left: 0;
    height: 100%;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    background: #00000056;
}
.nuanced .nuance_bar .nuance {
    min-width: 6px;
    height: 6px;
    max-width: 6px;
    position: absolute;
    border-radius: 50px;
    background-color: #fff;
}

.nuanced .trigger_icon {
    position: absolute;
    bottom: -20px;
    width: 30px;
    border-radius: 50px;
    height: 16px;
    border: 1px solid;
}
.nuanced .trigger_icon .icons svg {
    width: 7px;
    height: 9px;
}

#blue_print_modal .trigger_icon .icons svg path {
    fill: #8888a9;
}
#blue_print_modal .trigger_icon {
    border-color: #3d3d67;
}
#blue_print_modal .trigger_icon svg path {
    fill: #4c4c74;
}

.main_outside_eva .trigger_icon .icons svg path {
    fill: #32324e;
}
.main_outside_eva .trigger_icon {
    border-color: #8888a9;
}

.main_outside_eva .trigger_icon svg path {
    fill: #70708d;
}
.nuanced .trigger_icon::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 5px;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
    border-left: 1px dotted #3d3d67;
}
.main_outside_eva .nuanced .trigger_icon::before {
    border-color: #9292a8;
}
.average_box {
    padding-bottom: 15px;
    border-bottom: 1px solid var(--border-color);
}

.average_box label {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    min-width: 90px;
    text-align: center;
    line-height: 15px;
    padding: 3px 5px;
    border-radius: 5px;
    color: var(--heading-text-color);
    background-color: var(--label-bg-color);
}

.sensitivity .average_box label {
    opacity: 0.5;
    min-width: max-content;
}
.sensitivity .average_box label.active {
    opacity: 1;
}

.sensitivity .average_box label span {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    font-weight: 500;
    background-color: var(--border-color);
    text-align: center;
    color: var(--heading-text-color);
}

.apexcharts-tooltip.apexcharts-theme-light {
    background-color: transparent !important;
    box-shadow: none;
    border: 0 !important;
}

.content .arrow_btn_box {
    height: 50px;
}
.content .arrow_btn_box .arrow_disable {
    opacity: 0.5;
    cursor: not-allowed;
}

.arrow_btn_box .arrow_btn {
    width: 32px;
    background: transparent;
    height: 32px;
    border: 0;
    outline: none;
    border-radius: 5px;
}
.arrow_btn_box .arrow_btn:hover {
    background-color: var(--heading-bg);
}

.content .arrow_btn_box svg path {
    fill: #0f104480;
}

.tooltip-box {
    background-color: #0014ff !important ;
}

.tooltip-box p {
    font-size: 10px !important;
    font-weight: 500;
    line-height: 15px;
    color: #fff !important;
}

.tooltip-box.show_arrow .react-tooltip-arrow {
    display: block;
    background-color: #0014ff;
}

.description.executive_summaries_main {
    height: unset;
}

.tooltip-box.protocol_name_tooltips {
    width: 40%;
    text-align: center;
}

.ctrl_scroll {
    height: 100%;
    overflow-y: auto;
}
