.dialogPaper .MuiDialog-paperWidthSm {
    max-width: 100%;
}

.dialogBox {
    width: 656px;
    padding: 20px 24px;
    border-radius: 14px;
}

.dialogHeader {
    color: #434349bd;
    display: flex;
    padding: 4px 0 12px !important;
    font-size: 14px;
    align-items: center;
    font-family: 'Poppins' !important;
    line-height: 21px;
    border-bottom: 1px solid #0f10441a;
    justify-content: space-between;
}

.dialogContent {
    color: #161631bf;
    padding: 22px 0 16px !important;
    font-size: 12px;
    font-family: Poppins;
    line-height: 18px;
}

.dialog-button-wrap {
    padding-right: 0 !important;
}

.dialogButton {
    height: 40px;
    padding: 16px 24px;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 18px;
}

.eva-popover-main-div {
    inset: 3.8% 0px 0px -12% !important;
}

.divider {
    background: rgba(74, 149, 214, 0.75);
    width: 100%;
}

.bottom-2 {
    bottom: 2px;
}

.origin-left {
    transform-origin: left;
}

.left-130 {
    left: calc(50% - 6.771vw);
}

.fs-8 {
    font-size: 8px;
}

.orbit-labels {
    width: inherit;
    pointer-events: none;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
}

.disable-check {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.planet-description {
    word-break: break-word;
}

.height-450 {
    min-height: 450px;
}

.eva-sub-footer {
    font-size: 12px;
    line-height: 18px;
    color: rgba(74, 149, 214, 0.75);
}

.eva-footer {
    font-size: 12px;
    line-height: 18px;
}

.bottom-12 {
    bottom: 48px;
}

/* meta marker on hover popup */
.lineInfo {
    width: 140px;
    height: 12px;
}

.boxStyle {
    width: 140px;
    border-radius: 5px;
    z-index: 9999;
    background: rgba(6, 12, 57, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 108px !important;
}

.boxHeading {
    font-size: 10px;
    text-align: center;
    width: 123px;
    font-weight: 600;
    line-height: 15px;
    font-family: 'Poppins';
    color: #fff;
    margin-top: 5px;
    word-wrap: break-word;
}

.top_header {
    z-index: 999999;
}

.metaMaker_text {
    white-space: nowrap;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 4.167vw;
    font-family: Poppins;
    font-size: 0.521vw;
    font-weight: 400;
    bottom: 0;
    color: rgba(111, 191, 225, 0.75);
    line-height: 0.625vw;
}

.text-end {
    text-align: end !important;
}

.boxNucences {
    font-size: 10px;
    line-height: 15px;
    width: 123px;
    height: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    gap: 4px;
    display: flex;
    justify-content: center;
    border-radius: 50px;
    color: #fff;
    height: 16px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)),
        linear-gradient(90deg, #4a95d6 0%, #bc54ff 53.5%, #f14f98 99.5%);
}

.boxTrigger {
    font-size: 10px;
    line-height: 15px;
    width: 123px;
    height: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    gap: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    height: 16px;
    color: #fff;
    background: rgba(91, 89, 114, 0.5);
}

.boxNucenceText {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    font-family: 'Poppins';
}

.boxBorder {
    border-color: #ffffff26;
    width: 100%;
}

.eva-threshold {
    display: flex;
    justify-content: flex-end;
    height: 20px;
    align-items: center;
    margin-right: 10%;
}

@media (max-width: 991px) {
    .eva-threshold {
        margin-right: 25px;
    }
}

.boxStyle .boxBorder {
    width: 123px;
    margin: 0 auto;
}

.eva-threshold p {
    font-family: Poppins;
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    margin-right: 10px;
    color:  rgba(111, 191, 225, 0.75);
}

.eva-threshold p svg path {
    fill:rgba(111, 191, 225, 0.75);
}

.eva-threshold-count {
    width: 45px;
    height: 16px;
    margin-left: 4px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(111, 191, 225, 0.5);
    background-color: transparent;
    gap: 1px;
}

.count_limit.eva-threshold-count {
    padding: 0 6px;
}

.eva-threshold-count input {
    width: 100%;
    text-align: center;
    height: 100%;
    font-size: 10px;
    background-color: transparent;
    font-weight: 500;
    color: rgba(111, 191, 225, 0.75);
}

.eva-threshold-count button {
    font-size: 10px;
    color: rgba(111, 191, 225, 0.75);
    line-height: 12px;

    font-weight: 600;
}

.eva-nuance-represent {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #ffffffbf;
}

#popup {
    transform: translateY(5px);
}

#popup .boxStyle {
    position: absolute;
    bottom: 5px;
    right: 6px;
    border-radius: 5px !important;
    padding-bottom: 3px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 7px 0px #0f104426 !important;
}

.metaMakerText {
    bottom: 0;
    padding: 1px;
    z-index: 999999;
}
.metaMakerText.show_popup {
    height: 115px;
    z-index: 9999;
    width: 150px;
}

#popup .boxStyle .boxTrigger {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #13134c;
}

#popup .boxStyle .boxBorder {
    border-color: #0f104426 !important;
}

#popup .boxStyle .boxNucences {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #ffffff;
    text-align: left;
}

#popup .boxHeading {
    width: 123px;
    word-wrap: break-word;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #0f1044bf;
    text-align: center;
}

#pos_box .boxStyle {
    top: -103px !important;
    padding-bottom: 4px;
}

.padding-0 {
    padding: 0 !important;
}

.boxStyle .protocolType_text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    margin: 4px 0;
    text-align: center;
    -webkit-background-clip: text;
    gap: 5px;
    background-clip: text;
    color: transparent;
}

.boxStyle .protocolType_text img {
    height: 13px;
    margin-top: 2px;
    scale: 1.2;
}

.right-side-panel-outside-container {
    position: absolute;
    right: 15px;
    top: 3px;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
}
.disable_overly::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: transparent;
    width: 100%;
    z-index: 999;
}

.rsp-menu {
    width: 2.5vw;
    background-color: rgba(111, 191, 225, 0.2) !important;
    flex-direction: column;
    overflow: visible !important;
    display: flex;
    padding-top: 25px;
    gap: 14px;
    align-items: center;
    padding: 0.417vw;
    /* position: relative; */
}

.rsp-menu .horizontal_line {
    width: 100%;
    background-color: rgba(111, 191, 225, 0.1);
    height: 1px;
}

.rsp-menu-no-expand {
    border-radius: 7px;
}

.rsp-menu-no-expand .door_btn {
    top: 0;
    width: 1.667vw;
    border-radius: 4px;
    height: 1.667vw;
    transform: rotate(-180deg);
}

.rsp-menu-expanded {
    border-radius: 0px 7px 7px 0px;
}

.rsp-veritical-line {
    height: 100%;
    width: 1px;
    background: #6fbfe1;
    opacity: 0.1;
}

.rsp-expanded-container {
    width: 21.354vw;
    height: 100%;
    padding: 20px 20px;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 7px 0px 0px 7px;
    box-shadow: 0px 6px 16px 0px #0f104429;
}

.rps-default-background {
    background: #1a224f !important;
}

.rps-eval-background {
    background: rgba(96, 244, 200, 0.1) !important;
}

.rsp-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.667vw;
    border-radius: 4px;
    height: 1.667vw;
    margin: 7px 0;
}

.rsp-icon:hover {
    background: #6fbfe11a;
}

.rsp-icon-selected {
    background: rgba(111, 191, 225, 0.1);
}

.rsp-icon.rsp-icon-selected:hover {
    background: rgba(111, 191, 225, 0.1);
}

.evaluate-outside-container {
    position: absolute;
    right: 10px;
    top: 20px;
    width: 442px;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
}

.evaluate-hint-container {
    max-width: 31.667vw;
    position: absolute;
    padding: 14px 24px;
    background: linear-gradient(0deg, rgba(0, 20, 255, 0.25) -42.28%, rgba(0, 20, 255, 0) 42.28%);
    border-radius: 12px;
    color: rgba(255, 255, 255, 0.75);
    font-family: 'Poppins';
    bottom: -70px;
    gap: 12px;
    align-items: center;
    z-index: 9;
}

.action-btn {
    position: relative;
    background: rgba(255, 255, 255, 1);
    font-size: 10px;
    font-weight: 400;
    border-radius: 50px;
    height: 24px;
    align-items: center;
    border: none;
    max-width: 173px;
    overflow: hidden;
    scrollbar-width: none !important;
    white-space: nowrap;
    background-clip: padding-box;
    /* Ensure the background stays within the padding area */
    border: 1px solid transparent;
    /* Add transparent border to maintain border-radius */
    background-origin: border-box;
    background-image: linear-gradient(#fff 0 0), linear-gradient(90deg, #1c40f7 0.01%, #6fbfe1 99.49%);
    background-clip: content-box, border-box;
}

.action-btn::-webkit-scrollbar {
    display: none !important;
    /* For Chrome, Safari, and Edge */
}

/* .action-btn p {
    color: rgba(0, 20, 255, 1);
    padding: 5px 8px;
} */

.user-mood-container {
    padding-left: 15px;
}

.mood-slider {
    margin-left: 15px;
}

.action-text {
    color: rgba(0, 20, 255, 1);
    padding: 5px 8px;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.action-text span {
    display: inline-block;
    transition: transform 0.5s ease;
}

/* When hovering or focusing on the button, animate the span */
.action-btn:hover .action-text span,
.action-btn:focus .action-text span {
    animation-name: scroll-text;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0%);
    }

    90% {
        transform: translateX(-100%);
    }

    95% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(0%);
    }
}

/* Universal tooltip styles */
[data-title]:hover::after {
    position: absolute;
    min-width: fit-content;
    padding: 6px 10px;
    background: #0014ff;
    color: #ffffff;
    border-radius: 7px;
    white-space: nowrap;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    z-index: 1000; /* Ensure it appears above other elements */
    display: block;
    overflow: visible;
}

/* Optional: Adding some transition effect */
[data-title]:hover::before,
[data-title]:hover::after {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

/* By default, hide the tooltip */
[data-title]::before,
[data-title]::after {
    opacity: 0;
}

.metric-notification {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background-color: #feab49;
    border: 1px solid #22325d;
    transform: translate(100%, -130%);
}

.lines-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999 !important;
}

 .protocol_detail_edit_mode .protocol_detail {
    z-index: 9999;
}
 .protocol_detail_edit_mode .arrow_btn_box .arrow_btn svg path {
    fill: rgba(111, 191, 225, 0.5);
}

.protocol_detail_edit_mode .edit_btn svg{
    scale: 1.1;
}