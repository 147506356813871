.oversight_summaries {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 80%;
    z-index: 9;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    box-shadow: 0px 2px 4px 0px rgba(15, 16, 68, 0.12);
}

.sidebar_tab {
    height: 100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    width: 2.5vw;
    padding: 8px;
    background: rgba(15, 16, 68, 0.03);
    border-left: 1px solid rgba(15, 16, 68, 0.05);
}

.oversight_summaries .summaries_heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    padding: 15px;
    height: 57px;
    background: rgba(15, 16, 68, 0.03);
    line-height: 21px;
    text-align: left;
}

.report_heading {
    width: 11.458vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.summaries_heading button {
    width: 24px;
    height: 24px;
    border-radius: 5px;
}
.summaries_heading button svg path {
    fill: #0f104480;
}
.summaries_heading button:hover {
    background: rgba(15, 16, 68, 0.05);
}
.summaries_heading button:hover svg path {
    fill: #0f1044bf;
}

.summaries_heading .droup_btn {
    background: rgba(15, 16, 68, 0.05);
}

.no_summaries {
    padding: 15px;
    margin-top: 100px;
    border-radius: 15px;
    background: rgba(0, 20, 255, 0.03);
}

.no_summaries h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;

    color: rgba(15, 16, 68, 0.75);
}

.no_summaries p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.75);
}

.executive_summaries {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 20, 255, 0.1);
    gap: 10px;
    border-radius: 7px;
    width: 100%;
    height: calc(100% - 45px);
    background: rgba(0, 20, 255, 0.03);
}

.history_report.executive_summaries {
    background: rgba(15, 16, 68, 0.05);
    border: 1px solid rgba(15, 16, 68, 0.07);
}

.kpis_box1 {
    width: 100%;
}

.executive_summaries_main ul {
    list-style-type: disc;
    margin-left: 10px;
    padding-left: 10px;
}
.executive_summaries_main ol {
    list-style-type: decimal;
    margin-left: 10px;
    padding-left: 10px;
}

.executive_summaries_detail ol li,
.executive_summaries_detail p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.5);
}

.executive_summaries_detail {
    height: 100%;
    margin-right: -16px;
    overflow-y: auto;
    padding: 10px 20px;
    padding-left: 7px;
    transition: height 4s ease-in-out;
}

.executive_summaries_detail h3,
.executive_summaries_detail h2 {
    font-weight: 500;
    margin: 5px 0;
    font-size: 12px;
}

.executive_summaries_detail pre div table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.executive_summaries_detail pre div table,
.executive_summaries_detail pre div table th,
.executive_summaries_detail pre div table td {
    border: 1px solid black;
    font-size: 12px;
}

.executive_summaries_detail pre div table th,
.executive_summaries_detail pre div table td {
    padding: 3px;
    text-align: left;
    font-size: 12px;
}

.executive_summaries_detail pre div table th {
    background-color: #f2f2f2;
    font-size: 12px;
}

.summaries_height.executive_summaries_detail {
    height: 0px;
    overflow: hidden;
    padding: 0;
    transition: height 0s ease-in-out;
}
.executive_summaries_detail .check_input {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid rgba(15, 16, 68, 0.15);
}

.executive_summaries_detail .check_input:hover {
    border-color: rgba(12, 30, 234, 1);
}

.executive_summaries_detail h1 {
    font-family: Poppins;
    font-size: 12px;
    margin: 15px 0;
    font-weight: 500;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.75);
}

.executive_summaries ul li {
    margin-bottom: 10px;
}

.hide_scroll::-webkit-scrollbar {
    display: none;
}

.executive_summaries_detail label {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    width: max-content;
    margin-left: auto;
    display: block;
    line-height: 15px;
    color: rgba(15, 16, 68, 0.75);
}

.kpis_box label {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(15, 16, 68, 0.75);
}

.kpis_history {
    background: rgba(68, 68, 68, 1);
    min-height: 32px;
    width: 100%;
    padding: 0 10px;
}

.kpis_history .content p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}
.executive_summaries .copy_icon {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 10px;
    background: rgb(247 248 255);
}

.kpis_history .content {
    max-height: 32px;
    overflow-y: auto;
}

.kpis_history .content::-webkit-scrollbar {
    width: 8px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
}

.kpis_history .copy_icon {
    position: absolute;
    right: -10px;
    top: 50%;
    display: none;
    transform: translateY(-50%);
}

.kpis_history:hover .copy_icon {
    display: block;
}

.kpis_history .copy_icon svg path {
    fill: rgba(255, 255, 255, 1);
}

.kpis_history .content::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
}

.summaries_information .input_box {
    width: 75%;
    background: rgba(255, 255, 255, 1);
    height: 35px;
    border: 0;
    justify-content: start;
    margin-top: 0;
}

.summaries_information .input_box input {
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    width: 0;
    font-weight: 400;
    line-height: 18px;
    border-bottom: 1px solid rgba(15, 16, 68, 0.1);
    height: 100%;
    color: rgba(15, 16, 68, 0.5);
}
.summaries_information .input_box.showInput input {
    padding: 5px 20px 5px 35px;
    width: 100%;
}
.summaries_information .input_box svg {
    position: absolute;
    left: 10px;
    top: 50%;
    scale: 1.3;
    transform: translateY(-50%);
}

.summaries_information .input_box svg path {
    fill: rgba(15, 16, 68, 0.548);
}
.summaries_information .input_box:hover {
    border-color: #0f104433;
}

.msg_history .msg_content {
    background: rgba(0, 20, 255, 0.03);
    padding: 10px;
    display: flex;
    border: 1px solid rgba(0, 20, 255, 0.1);
    border-radius: 15px;
    flex-direction: column;
    gap: 5px;
}

.msg_history .prev_data.msg_content {
    background: rgba(15, 16, 68, 0.03);
    border-color: rgba(15, 16, 68, 0.1);
}

.msg_history .msg_content:hover {
    background: rgba(0, 20, 255, 0.05);
    border: 1px solid rgba(15, 16, 68, 0.1);
}

.msg_history .versions p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    color: rgba(15, 16, 68, 0.75);
}

.msg_history {
    height: calc(100% - 110px);
    padding-right: 7px;
    overflow-y: auto;
    margin-top: 8px;
}

.sessions .msg_history {
    height: calc(100% - 120px);
}

.scrollbar::-webkit-scrollbar {
    background: rgba(15, 16, 68, 0.03);
    width: 6px;
    border-radius: 50px;
    height: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.05);
    border-radius: 50px;
    height: 5px;
}

.msg_history .details .time {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    width: fit-content;
    margin: 10px auto;
    text-transform: capitalize;
    display: block;
    text-align: center;
    color: rgba(15, 16, 68, 0.5);
}

.msg_content .terminal_box {
    background: rgba(68, 68, 68, 1);
    width: 24px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.msg_history .msg_content .head h1 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.75);
}

.msg_history .msg_content .head span {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    color: rgba(15, 16, 68, 0.75);
}

.msg_history .msg_content .dis {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
    text-align: left;
    color: rgba(15, 16, 68, 0.75);
}

.droup_box {
    position: absolute;
    right: 0;
    top: 24px;
    box-shadow: 0px 6px 16px 0px rgba(15, 16, 68, 0.16);
    min-width: 186px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 96px;
    background-color: #fff;
    border-radius: 5px;
}

.Delete_btn:hover .tooltip_box,
.History_btn:hover .tooltip_box {
    display: flex;
    left: 50%;
    z-index: 9;
    transform: translateX(-50%);
    background: rgba(0, 20, 255, 1);
}

.droup_box ul li {
    font-family: Poppins;
    font-size: 12px;
    height: 32px;
    font-weight: 400;
    line-height: 18px;
    padding: 0 20px;
    text-align: start;
    color: rgba(15, 16, 68, 0.75);
}

.droup_box ul li:hover {
    background: rgba(15, 16, 68, 0.03);
}

.meter_box {
    position: relative;
    cursor: pointer;
}

.meter_box:hover .tooltip_box {
    display: flex;
    padding: 5px;
    align-items: center;
    flex-direction: column;
    top: -100%;
    transform: translateY(-100%);
    z-index: 99;
    height: auto;
}

.meter_box:hover .tooltip_box p {
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    font-weight: 500;
    line-height: 15px;
}

/* sidebar_tab */

.sidebar_tab .open_close_btn {
    height: 80px;
    margin: auto;
    display: flex;
    width: 80%;
    outline: none;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(15, 16, 68, 0.05);
}

.sidebar_tab ul li {
    width: 1.667vw;
    height: 1.667vw;
    margin: auto;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.sidebar_tab ul li:hover {
    background: rgba(15, 16, 68, 0.05);
}

.sidebar_tab ul li.active {
    background: rgba(15, 16, 68, 0.05);
}

.sidebar_tab ul li:hover .tooltip_box {
    display: flex;
    z-index: 88;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
}

.sidebar_tab ul li svg path {
    fill: #0f104480;
}

.sidebar_tab ul li.active svg path,
.sidebar_tab ul li:hover svg path {
    fill: #0f1044bf;
}
.executive_summaries .error {
    font-size: 12px;
    text-align: center;
    color: #e42d2d;
    padding: 15px 0;
}

.show_screen .sidebar_tab {
    border: 0;
}

.show_screen.oversight_summaries .sidebar_tab {
    border-radius: 7px;
}

.show_screen .door_btn {
    transform: rotate(-180deg);
}

.data_unavailable {
    flex-direction: column;
    display: flex;
    justify-content: center;
    height: 450px;
    align-items: center;
}

.data_unavailable .img_box {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background: rgba(15, 16, 68, 0.03);
}

.data_unavailable h1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 7px 0;
    text-align: center;
    color: rgba(15, 16, 68, 0.75);
}

.data_unavailable h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: rgba(15, 16, 68, 0.5);
}

.protocol_icon {
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.inside_screen {
    height: 100%;
    width: 20.521vw;
}

.main_report_list {
    height: 100%;
}

.sessions .main_report_list .sort_box .m_select button {
    border: 0;
    border-radius: 0;
    height: auto;
}
.sessions .main_report_list .sort_box {
    height: 40px;
    display: flex;
    align-items: center;
}
.sessions .main_report_list .sort_box ul {
    width: 150px;
    left: unset !important;
    right: 0;
}
.inside_screen .tooltip_box {
    height: max-content;
    padding: 5px;
    width: max-content;
    background: rgba(0, 20, 255, 0.75);
}

.protocol_icon:hover .tooltip_box {
    display: block !important;
    top: -90%;
    transform: translateY(-90%);
    padding: 5px;
    left: 0;
    z-index: 9;
}

.inside_screen .tooltip_box p {
    padding: 1px 0;
    display: flex !important;
}

.inside_screen .tooltip_box p img {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    object-fit: contain;
}

.msg_content .info_box {
    position: absolute;
    right: 15px;
    border-radius: 5px;
    top: 15px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.msg_content .info_box:hover {
    background-color: #0f10440d;
}

.main_report_list .sort_box {
    width: max-content;
    margin-left: auto;
    margin-top: 10px;
}
.main_report_list .sort_box .start_icon {
    z-index: 999;
    left: 0;
}

.main_msg_details .sort_box button {
    border: 0;
    padding-right: 27px;
}

.main_msg_details .sort_box button > div {
    padding: 0 5px;
}

.versions_history {
    padding: 5px 20px;
    height: calc(100% - 15px);
}
.versions_history .main_msg_details {
    height: auto;
}

.versions_history .main_msg_details .sort_box {
    width: min-content;
    margin-left: auto;
}

.main_version_list {
    height: calc(100% - 100px);
    overflow-y: auto;
}
.main_version_list .executive_summaries_detail {
    margin: 0;
}

.versions_history ul .no_data_found {
    font-size: 12px;
    margin: auto;
    width: fit-content;
    color: #727291;
}

.versions_history ul {
    padding: 0;
    overflow-y: unset;
}

.main_msg_details .no_data {
    font-size: 13px;
    width: fit-content;
    color: #727291;
    text-transform: capitalize;
    margin: 15px auto;
}

.main_msg_details .sort_box svg path {
    fill: rgba(15, 16, 68, 0.75);
}

.main_msg_details .sort_box .start_icon {
    left: 0;
    z-index: 9;
}

.main_msg_details .sort_box button .down_arrow {
    display: none;
}

.main_msg_details .sort_box button .truncate {
    color: rgba(15, 16, 68, 0.5);
    opacity: 1 !important;
    width: 150px;
}
.inside_screen .main_msg_details .sort_box button .truncate {
    width: auto;
}
.main_msg_details .sort_box button:hover .truncate {
    color: rgba(15, 16, 68, 0.75);
}
.versions_history .main_msg_details .sort_box svg path {
    fill: rgba(15, 16, 68, 0.5);
}
.versions_history .main_msg_details .sort_box:hover svg path {
    color: rgba(15, 16, 68, 0.75);
}

.main_msg_details .sort_box .m_select ul {
    padding: 4px;
    right: 8px;
    width: 120px;
    left: unset !important;
    border: 0 !important;
    box-shadow: 0px 6px 16px 0px rgba(15, 16, 68, 0.16);
}

.main_msg_details .sort_box .m_select ul li {
    padding: 0;
    display: flex;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    height: 32px;
}

.main_msg_details .sort_box .m_select ul li:hover {
    background: rgba(15, 16, 68, 0.03);
}

.copy_icon {
    padding-right: 22px;
}

.versions_history ul li .report_box {
    width: 90%;
    border-radius: 7px;
    padding: 10px;
    height: 32px;
    cursor: pointer;
    background: rgba(15, 16, 68, 0.05);
    border: 1px solid rgba(15, 16, 68, 0.1);
}

.versions_history ul li .report_box:hover {
    background: rgba(15, 16, 68, 0.08);
}

.versions_history ul li .report_box .report_name {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: rgba(15, 16, 68, 0.5);
}

.versions_history ul li .report_box .report_time {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    color: rgba(15, 16, 68, 0.5);
}

.versions_history ul li input {
    width: 20px;
    scale: 1.22;
    accent-color: rgba(0, 20, 255, 1);
}

.single_report .executive_summaries {
    background: rgba(15, 16, 68, 0.05);
    border: 1px solid rgba(15, 16, 68, 0.1);
}

.oversight_top .sort_box {
    margin-left: unset;
    width: 208px;
    margin: 0;
}

.main_msg_details .oversight_top .sort_box .m_select ul {
    left: 0 !important;
    width: auto;
}

.main_msg_details .oversight_top .sort_box button {
    border: 1px solid rgba(15, 16, 68, 0.1);
    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
}
.main_msg_details .oversight_top .sort_box button:hover {
    border-color: #0f104433;
}

.main_msg_details .oversight_top .create_report .report_loader {
    border-right-color: #fff !important;
}

.oversight_top .create_report {
    max-width: 128px;
    width: 100%;
    border-radius: 16px;
    color: rgba(255, 255, 255, 1);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    line-height: 18px;
    height: 40px;
    background: rgba(15, 16, 68, 1);
}

.oversight_top .create_report.disable {
    opacity: 0.5;
    pointer-events: none;
}

.no_report {
    background: rgba(0, 20, 255, 0.03);
    border: 1px solid rgba(0, 20, 255, 0.1);
    width: 352px;
    padding: 15px;
    position: absolute;
    top: 40%;
    min-height: 116px;
    border-radius: 15px;
}

.no_report h1 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 7px;
    color: rgba(15, 16, 68, 0.75);
}

.no_report h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    color: rgba(15, 16, 68, 0.75);
    line-height: 18px;
}

.no_report h6 span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}
.shield_oversight .no_report {
    top: 26%;
}

.learning_box button {
    background: rgba(15, 16, 68, 1);
    width: 187px;
    height: 32px;
    gap: 8px;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 35px;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.learning_tab button {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.learning_tab button.active {
    background: rgba(15, 16, 68, 0.05);
}

.learning .msg_history .msg_content .time {
    margin: 0 !important;
}

.learning .progress_box {
    height: 40px;
    gap: 15px;
    padding: 15px;
    background-color: #fff;
}

.learning .progress_box .outside_progress {
    width: 160px;
    height: 4px;
    background: rgba(15, 16, 68, 0.05);
    border-radius: 50px;
}

.learning .progress_box .inside_progress {
    background: rgba(0, 20, 255, 1);
    height: 100%;
    border-radius: 50px;
}

.learning .progress_bottom p,
.learning .progress_bottom .progress_box p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(15, 16, 68, 0.75);
}

.learning .details .msg_content {
    background-color: unset;
    padding: 0;
    overflow: hidden;
}

.learning .details .msg_content .top_msg_box {
    background: rgba(0, 20, 255, 0.03);
    padding: 15px;
}

.learning .msg_history .msg_content .dis {
    margin: 4px 0;
}

.learning .msg_history {
    max-height: 387px;
}

.scrollbar::-webkit-scrollbar {
    background: rgba(15, 16, 68, 0.03);
    width: 5px;
    border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.1);
    border-radius: 50px;
}

.inside_screen .main_msg_details {
    height: calc(100% - 60px);
    padding: 0.781vw 1.302vw 0.781vw 1.302vw;
}
.executive_summaries_main {
    padding: 0 25px 0 25px;
    height: calc(100% - 130px);
}
.Creation_date {
    padding: 0 25px;
    margin-bottom: 25px;
    height: 50px;
    border-bottom: 1px solid rgba(15, 16, 68, 0.1);
}
.Creation_date span {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    color: rgba(15, 16, 68, 0.5);
}
.panel_top_bar .sort .sort_list.report_filters {
    width: 270px;
    padding-bottom: 10px;
}

.panel_top_bar .sort .sort_list.report_filters .filter_content h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.75);
    margin-bottom: 8px;
}

.panel_top_bar .sort .sort_list.report_filters .filter_content button {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    width: 68px;
    height: 24px;
    border-radius: 5px;
    color: rgba(15, 16, 68, 0.35);
}
.panel_top_bar .sort .sort_list.report_filters .filter_content {
    margin: 0 15px;
}

.panel_top_bar .sort .sort_list.report_filters .filter_content button.active {
    color: rgba(0, 20, 255, 1);
    border: 1px solid rgba(0, 20, 255, 1);
}
.panel_top_bar .sort .sort_list.report_filters .sort_head {
    margin-bottom: 10px;
}
.panel_top_bar .sort .sort_list.report_filters .apply_filter button {
    min-width: 61px;
    height: 32px;
    border-radius: 7px;
}
.panel_top_bar .sort .sort_list.report_filters .apply_filter {
    margin: 0 10px;
}
.panel_top_bar .sort .sort_list.report_filters .apply_filter .cancel {
    border: 1px solid rgba(15, 16, 68, 0.1);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
}

.panel_top_bar .sort .sort_list.report_filters .apply_filter .apply {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #fff;
    background: rgba(0, 20, 255, 1);
}

.panel_top_bar .sort .sort_list.report_filters .sort_head button {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    color: rgba(0, 20, 255, 0.75);
}
.panel_top_bar .sort.active svg {
    transform: rotate(180deg);
}

.versions_history .panel_top_bar {
    height: 50px;
}

.history_report .copy_icon {
    background-color: rgb(243 243 245);
}
.report_list.no_protocol {
    height: 80%;
}

.info_box .action .action-list {
    top: 10px;
    right: 0;
    z-index: 8;
    border-radius: 7px;
    background-color: #fff !important;
    box-shadow: 0px 6px 16px 0px rgba(15, 16, 68, 0.16);
    width: 70px;
}

.info_box .action .action-list ul li {
    font-size: 12px;
    color: #0f1044bf;
    padding: 10px 15px;
    width: 100%;
}

.info_box .action .action-list.active,
.info_box .action .action-list ul li.active,
.info_box .action .action-list ul li:hover {
    background: rgba(15, 16, 68, 0.03);
    opacity: 1;
}
@media (max-width: 1400px) {
    .oversight_top {
        margin-top: 0;
        flex-direction: column;
        gap: 5px;
    }
    .main_msg_details .oversight_top .sort_box button {
        height: 35px;
        width: 100%;
    }
    .oversight_top .sort_box {
        width: 100%;
    }
    .oversight_top .create_report {
        height: 30px;
        font-size: 10px;
        max-width: 100%;
    }
}
