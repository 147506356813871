#ctrl {
    --panel-bg: rgba(15, 16, 68, 0.03);
    --heading-bg: rgba(15, 16, 68, 0.03);
    --box-bg: rgba(15, 16, 68, 0.03);
    --heading-text-color: rgba(15, 16, 68, 0.75);
    --text-color: rgba(15, 16, 68, 0.5);
    --border-color: rgba(15, 16, 68, 0.1);
    --scroll-bar-color: rgba(15, 16, 68, 0.03);
    --scroll-thumb-color: rgba(15, 16, 68, 0.031);
    --label-bg-color: rgba(15, 16, 68, 0.05);
    --skelton-bg: rgba(15, 16, 68, 0.03);
    --box-shadow: none;
    --width: auto;
    --left: 0;
    --top: 50%;
    --topMins: -50%;
    --position: absolute;
    --radius: 0;
    --border-left: rgba(15, 16, 68, 0.1);
    --svg-fill: slategrey;
    --horizontal_line_border: rgba(15, 16, 68, 0.1);
    --icon_hover: rgba(15, 16, 68, 0.05);
    --main_height: 80%;
}

#edit-mode {
    --panel-bg: rgba(111, 191, 225, 0.1);
    --heading-bg: rgba(111, 191, 225, 0.1);
    --box-bg: rgba(17, 17, 63, 1);
    --heading-text-color: rgba(111, 191, 225, 0.75);
    --text-color: rgba(111, 191, 225, 0.5);
    --border-color: rgba(255, 255, 255, 0.1);
    --scroll-bar-color: rgba(111, 191, 225, 0.1);
    --scroll-thumb-color: rgba(111, 191, 225, 0.1);
    --label-bg-color: rgba(111, 191, 225, 0.05);
    --skelton-bg: rgba(111, 191, 225, 0.033);
    --left: 0.5%;
    --box-shadow: 0px 4px 10px 0px rgba(15, 16, 68, 0.12);
    --width: 23.021vw;
    --position: absolute;
    --radius: 0;
    --topMins: 0;
    --top: 0;
    --svg-fill: rgba(111, 191, 225, 0.75);
    --border-left: rgba(42, 64, 106, 1);
    --horizontal_line_border: rgba(111, 191, 225, 0.1);
    --icon_hover: rgba(111, 191, 225, 0.05);
    --main_height: calc(100%);
}

.main_left_panel {
    top: var(--top);
    overflow: hidden;
    z-index: 9;
    left: 10px;
    box-shadow: 0px 2px 4px 0px rgba(15, 16, 68, 0.12);
    position: absolute;
    transform: translateY(var(--topMins));
    height: var(--main_height);
    background-color: var(--panel-bg);
    border-radius: 7px;
}

.left_bar {
    width: 2.5vw;
    padding: 0.417vw;
    background: var(--heading-bg);
    height: 100%;
    border-radius: 7px;
}

.door_btn svg path {
    fill: var(--svg-fill);
    fill-opacity: 1;
}

.show_panel .left_bar {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.door_btn {
    width: 1.667vw;
    border-radius: 4px;
    height: 1.667vw;
}
.show_panel .left_bar .door_btn svg {
    transform: rotate(180deg);
}
.horizontal_line {
    width: 100%;
    background-color: var(--horizontal_line_border);
    height: 1px;
}

.left_bar ul li {
    width: 1.667vw;
    border-radius: 4px;
    height: 1.667vw;
    margin: 7px 0;
}
.door_btn:hover,
.left_bar ul li:hover,
.left_bar ul li.active {
    background: var(--icon_hover);
}

.left_bar ul li svg {
    opacity: 0.6;
}
.left_bar ul li svg circle {
    stroke: var(--svg-fill);
}
.left_bar ul li svg rect {
    fill: var(--svg-fill);
}

.left_bar ul li.active svg {
    opacity: 1;
}
.main_left_panel .right_content_panel {
    z-index: 9;
    height: 100%;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    overflow: hidden;
    border-left: 1px solid;
    border-color: var(--border-left);
    width: 20.521vw;
}
.main_left_panel .right_content_panel .heading {
    width: 100%;
    padding: 15px;
    background-color: #fff;
    height: 56px;
    background: var(--heading-bg);
}
.main_left_panel .right_content_panel .heading h6 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: var(--heading-text-color);
}

.protocol_edit_mode {
    height: 100%;
    overflow-y: auto;
}

.protocol_edit_mode .main_sidebar_box .protocol_detail {
    height: 100%;
}

.right_content_panel .heading .plus_btn {
    background: rgba(111, 191, 225, 0.85);
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
