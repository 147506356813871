.tabs-btn.active {
    color: #5a44ff;
}

.tabs-btn.active::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #5a44ff;
    left: 0;
    bottom: 0;
}

.analytics-container .card {
    border-color: rgba(15, 16, 68, 0.1);
}

.analytics-container .text-dark-color {
    color: rgba(15, 16, 68, 0.75);
}

.title-box .list:before {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    background: #4d78ff;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

/* drop down style == */

.title-box {
    width: 276px;
    position: relative;
}
.title-box .MuiInputBase-input {
    font-family: Poppins;
    font-size: 14px;
    padding-left: 0;
    font-weight: 500;
    line-height: 21px;
}

.title-box .select_drop_down {
    border: 0;
    width: 100%;
}
#menu- .MuiMenu-list {
    background-color: #fff !important;
    border-radius: 5px;
    padding: 0;
    box-shadow: none;
}
#menu- .MuiMenu-list li {
    padding: 8px 10px;
    font-size: 10px;
    display: block;
    font-family: Poppins;
    width: 100%;
    font-weight: 400;
    color: #000 !important;
    background-color: #fff !important;
}
#menu- .MuiMenu-list li:hover {
    background-color: #7dafe21e !important;
}
#menu- .MuiPopover-paper {
    box-shadow: none;
    border: 1px solid #000;
    max-height: 200px;
    overflow-y: auto;
    width: 276px;
    transition: none !important;
    transform-origin: 0 !important;
}
.title-box .select_drop_down {
    z-index: 9;
}
#menu- .MuiMenu-list li.disable {
    cursor: auto !important;
    color: #0f104480 !important;
}
.title-box .select_drop_down > div {
    z-index: 999;
    width: 100%;
}
.title-box .select_drop_down fieldset {
    border: 0;
}
/* drop down style end == */

.search-input {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 35px;
    padding: 10px 15px 10px 43px;
    color: rgba(255, 255, 255, 0.5);
}

.search-input:focus {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.analytics-log-list {
    height: 60vh;
    overflow-y: auto;
}

.analytics-log-list li {
    background: transparent;
    padding: 5px 0;
}

.analytics-log-list li p {
    color: rgba(255, 255, 255, 0.75);
    font-family: Courier Prime;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    line-height: 13.48px;
    text-align: left;
}

.analytics-log-list li span {
    margin-left: 10px;
}
.analytics-log-list li p .date_time {
    color: rgba(111, 191, 225, 1);
}
.analytics-log-list li .log_dots::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    left: -2px;
}

.log-select button span {
    color: #fff;
    opacity: 1;
    line-height: 1;
}

.log-select button span svg {
    fill: #fff;
}

.log-select button {
    background: rgba(255, 255, 255, 0.25);
    width: auto;
    max-width: fit-content;
    padding-left: 34px;
    color: #fff;
    padding-right: 34px;
}

.custom-dropdown button {
    width: fit-content;
    border-color: transparent;
}

.custom-dropdown button:hover {
    border-color: #e6e6ec;
}

.custom-dropdown button .truncate {
    font-size: 14px;
    font-weight: 500;
    color: rgba(15, 16, 68, 0.75);
    line-height: 1;
}

.custom-dropdown button:focus {
    outline: none;
    outline-offset: 0;
    border: none;
}

.custom-date-picker .react-datepicker-wrapper {
    overflow: hidden;
    border-radius: 35px;
    position: relative;
    width: 125px;
    padding: 9px 16px;
    height: 40px;
}

.custom-date-picker .react-datepicker-wrapper:before {
    position: absolute;
    content: '';
    background: url('./../../../../assets/icons/icon-calendar.png') no-repeat;
    width: 13px;
    height: 12px;
    z-index: 1;
    right: 16px;
}

.left-label {
    left: -18px;
    top: 45%;
}
.height-32 {
    min-height: 32px !important;
}
.width-136 {
    width: 136px;
}
.width-282 {
    width: 282px;
}
.width-292 {
    width: 292px;
}
.disable_border_color {
    border: none !important;
    background-color: #fff !important;
}

.height-10 {
    height: 40px !important;
}
