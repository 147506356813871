.metric .metric_filter {
    min-width: 95px;
    height: 32px;
    border-radius: 7px;
}

.metric .metric_filter_active {
    background: rgba(15, 16, 68, 0.05);
}
.metric .metric_filter:hover {
    background: rgba(15, 16, 68, 0.05);
}

.metric .metric_filter span {
    font-size: 12px;
    font-weight: 400;
    color: rgba(15, 16, 68, 0.75);
}

.metric .metric_filter .metric_filter_box svg path {
    fill: rgba(15, 16, 68, 0.75);
}

.metric .metric_filter_active .metric_filter_box span,
.metric .metric_filter:hover .metric_filter_box span {
    color: rgba(15, 16, 68, 1);
}
.metric .metric_filter_active .metric_filter_box svg path,
.metric .metric_filter:hover .metric_filter_box svg path {
    fill: rgba(15, 16, 68, 1);
}
.metric .metric_filter .metric_filter_box {
    width: 100%;
    height: 100%;
}
.metric .metric_filter .filter_item_box {
    padding: 12px 4px;
    width: 540px;
    top: 35px;
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    left: 0;
    z-index: 9;
    box-shadow: 0px 6px 16px 0px rgba(15, 16, 68, 0.16);
    background-color: #fff;
}

.metric .metric_filter .filter_item_box button {
    font-size: 9px;
    font-weight: 400;
    padding: 0 9px;
    height: 28px;
    border: 1px solid rgba(15, 16, 68, 0.1);
    border-radius: 50px;
    line-height: 15px;
    color: rgba(15, 16, 68, 1);
}

.metric .metric_filter .filter_item_box button.selected {
    border-color: rgba(0, 20, 255, 1);
    box-shadow: 0px 2px 2px 0px rgba(15, 16, 68, 0.15);
}
.metric_indicators_scroll {
    overflow-x: auto;
    padding-bottom: 10px;
}
.metric_indicators_scroll::-webkit-scrollbar {
    width: 5px;
    background: rgba(15, 16, 68, 0.03);
    height: 6px;
    border-radius: 10px;
}

.metric_indicators_scroll::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.05);
    border-radius: 10px;
}

.metric_indicators {
    display: flex;
    gap: 12px;
    width: max-content;
}
.metric_indicators .metric_indicators_box {
    width: 281px;
    cursor: pointer;
    height: 48px;
    padding: 0 15px;
    border-radius: 15px;
    border: 1px solid rgba(15, 16, 68, 0.1);
}
.metric_indicators .metric_indicators_box:hover {
    box-shadow: 0px 2px 6px 0px rgba(15, 16, 68, 0.15);
}
.metric_indicators .metric_indicators_box button {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    padding: 0 5px;
    height: 28px;
    border: 1px solid rgba(15, 16, 68, 0.1);
    border-radius: 50px;
    line-height: 15px;
    color: rgba(15, 16, 68, 1);
}

.metric_indicators .metric_indicators_box .metric_box .indicators {
    width: 12px;
    height: 12px;
    background: rgba(97, 220, 54, 1);
    border-radius: 4px;
}
.metric_indicators .metric_indicators_box .metricsStar svg {
    opacity: 0.6;
}

.metric_indicators .metric_indicators_box.active {
    background: rgba(97, 220, 54, 0.03);
    box-shadow: 0px 2px 6px 0px rgba(15, 16, 68, 0.15);
    border-color: rgba(97, 220, 54, 0.25);
}

.no_data_found {
    color: rgba(15, 16, 68, 0.75);
    font-size: 12px;
}
.metric_scroll_tooltips{
    max-height: 100px;
    overflow-y: auto;
    padding: 5px;
}
.trend_graph_tooltip .metric_scroll_tooltips{
    padding: 0 !important;
    margin-bottom: 5px;
}
.metric_scroll_tooltips::-webkit-scrollbar {
    background: #7281ff;
    width: 4px;
    border-radius: 20px;
}

.metric_scroll_tooltips::-webkit-scrollbar-thumb {
    width: 3px;
    background: #0015ffa2;

}